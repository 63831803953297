/* inter-regular - latin */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/inter-v2-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/inter-v2-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-500 - latin */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("./fonts/inter-v2-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/inter-v2-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("./fonts/inter-v2-latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/inter-v2-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
    /* overflow-x: hidden; */
    width: 100vw;
}

#root {
    background-color: #ebebeb;
}

.video-message video {
    border-radius: 12px;
}

.conversation-tab-list {
    scrollbar-width: none; /* Firefox */
}

.conversation-tab-list::-webkit-scrollbar {
    display: none;
}

.conversation-list-item .compact {
    display: inherit;
}

.conversation-list-item .compact ol,
.conversation-list-item .compact ul {
    display: inherit;
    margin-left: 1.25rem;
    gap: 1.2rem;
}

.message-container ol,
.message-container ul {
    padding-inline-start: 1rem;
}

.message-container a:not(.menu-link) {
    color: inherit;
    text-decoration: underline;
}

body {
    min-height: 0;
    max-height: 100vh;
    /* overflow: hidden; */
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
